<template>
	<div class="account">
		<h1 class="page_title">{{ $route.meta.title }}</h1>
		<a-form
			class="ant-advanced-search-form"
			:form="form"
			@submit="handleSearch"
		>
			<a-row :gutter="24">
				<a-col :span="6">
					<a-form-item label="公司简称">
						<a-select
							placeholder="请选择公司"
							v-decorator="['companyId', { initialValue: companyId }]"
						>
							<!-- <a-select-option key="company-0" :value="0">
								全部
							</a-select-option> -->
							<a-select-option
								v-for="(item, index) in companyList"
								:key="'company' + index"
								:value="item.id"
							>
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
				<a-col :span="6">
					<a-form-item label="角色">
						<a-select
							placeholder="请选择角色"
							v-decorator="['roleType', { initialValue: -1 }]"
						>
							<a-select-option
								v-for="(item, index) in roleList"
								:key="'role' + index"
								:value="item.value"
							>
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
				<a-col :span="6">
					<a-form-item label="姓名">
						<a-input
							v-decorator="['name']"
							placeholder="请输入姓名"
							autocomplete="off"
						/>
					</a-form-item>
				</a-col>
				<a-col
					:span="6"
					:style="{
						textAlign: 'left',
						height: '40px',
						lineHeight: '40px'
					}"
				>
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button
						:style="{ marginLeft: '8px' }"
						@click="handleReset"
					>
						重置
					</a-button>
				</a-col>
			</a-row>
		</a-form>
		<a-table
			rowKey="id"
			:columns="table_col"
			:data-source="table_data"
			:pagination="table_pagination"
			:loading="table_loading"
			@change="tableChange"
		>
			<template slot="status" slot-scope="text">
				<a-badge :status="text == 0 ? 'error' : 'success'" 
						:text="text == 0 ? '冻结' : '正常'"/>
			</template>
			<template
				slot="active"
				slot-scope="text, record"
			>
				<a-popconfirm
					title="确认重置密码为：Aa123456789"
					ok-text="确定"
					cancel-text="取消"
					@confirm="resetPwd(record)"
				>
					<a href="#">重置密码</a>
				</a-popconfirm>
				
			</template>
		</a-table>
	</div>
</template>
<script>
	import { 
		companyUserPage,
		companyUserReset,
		companyList} from "@/api/index";
	export default {
		name: "Account",
		data() {
			return {
				form: this.$form.createForm(this),
				// 所有角色列表
				roleList: [{
					value: -1,
					name: "全部"
				},{
					value: 1,
					name: "主管理员"
				},{
					value: 0,
					name: "普通"
				}],
				// 所有公司列表
				companyList: [],
				companyName: "",
				companyId: null,
				// 列
				table_col: [
					{
						title: "序号",
						dataIndex: "key",
						customRender: (text, record, index) => {
							return (
								(this.table_pagination.current - 1) *
									this.table_pagination.pageSize +
								(index + 1)
							);
						}
					},
					{
						title: "公司简称",
						dataIndex: "companyName"
					},
					{
						title: "角色",
						dataIndex: "roleName"
					},
					{
						title: "账号名",
						dataIndex: "userName"
					},
					{
						title: "姓名",
						dataIndex: "name",
						customRender: ( record) => {
							return record ? record :"---";
						}
					},
					{
						title: "手机号",
						dataIndex: "phone",
						customRender: ( record) => {
							return record ? record :"---";
						}
					},
					{
						title: "最后访问",
						dataIndex: "recentVisitTime",
						customRender: ( record) => {
							return record ? record :"---";
						}
					},
					{
						title: "操作",
						dataIndex: "active",
						scopedSlots: {
							customRender: "active"
						}
					}
				]
			};
		},
		created() {

		},
		mounted() {
			this.getCompanyList();
		},
		methods: {
			// 账号列表
			getTableList(values) {
				this.table_loading = true;

				let params = values || this.form.getFieldsValue();
				companyUserPage({
							companyId: params.companyId
						},{
							current: this.table_pagination.current,
							pageSize: this.table_pagination.pageSize,
							...params,
							roleType: params.roleType == -1 ? null : params.roleType
						})
						.then(res => {
							this.table_data = res.data.list;
							this.table_pagination.total = res.data.total;
						})
						.catch(() => (this.table_data = []))
						.finally(() => (this.table_loading = false));
			},
			// 公司列表
			getCompanyList() {
				companyList({}).then(res => {
					this.companyList = res.data;
					
					//默认需要选中第1个
					if(this.companyList.length > 0){
						this.companyId = this.companyList[0].id;
						this.companyName = this.companyList[0].name;
					}
				}).finally(() => {
					this.getTableList();
				});
			},

			// 搜索
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
					if (!error) {
						if(values.companyId){
							let company = this.companyList.find((n) => n.id == values.companyId);
							this.companyId = company.id;
							this.companyName = company.name;
							
							this.getTableList(values);
						}else{
							this.$message.error("公司简称不能为空");
						}
					}
				});
			},
			// 重置
			handleReset() {
				this.form.resetFields();
				this.getTableList();
			},
			
			// 重置密码
			resetPwd(record) {
				companyUserReset({
						companyId: this.companyId
					},{
						id: record.id,
						password: "Aa123456789"
					})
					.then(() => {
						if (record.id === this.$store.state["_userInfo"].id) {
							this.$message.success("密码重置成功, 请重新登录");
							this.$router.push({ name: "login" });
						} else {
							this.$message.success("密码重置成功");
						}
					});
			}
		}
	};
</script>
<style lang="scss">
	.account {
		.ant-advanced-search-form .ant-form-item {
			display: flex;
		}

		.ant-advanced-search-form .ant-form-item-control-wrapper {
			flex: 1;
			overflow: hidden;
		}
	}
</style>
